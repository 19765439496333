<template>
  <div class="inbound">
    <div class="info">
      <h1>库存分布明细</h1>
      <div class="filter-term">
        <a-row>
          <a-col :span="7">
            <label>仓库</label>
            <a-input placeholder="请输入" />
          </a-col>
          <a-col :span="7">
            <label>物品</label>
            <a-input placeholder="请输入物品名称/编码" />
          </a-col>
          <a-col :span="7">
            <label>类型</label>
            <a-select>
              <a-select-option value="全部">全部</a-select-option>
              <a-select-option value="成品">成品</a-select-option>
              <a-select-option value="物料">物料</a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <label>库存状态</label>
            <a-select>
              <a-select-option value="全部">全部</a-select-option>
              <a-select-option value="可用">可用</a-select-option>
              <a-select-option value="冻结">冻结</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7">
            <label>单据编号</label>
            <a-input placeholder="请输入" />
          </a-col>
          <a-col :span="7">
            <a-button type="primary">查询</a-button>
          </a-col>
        </a-row>
      </div>
      <div class="cutover">
        <a-button class="add-btn" @click="goAdd">切换为物料视图</a-button>
      </div>
      <a-table :columns="columns" :data-source="data" :pagination="pagination">
        <a slot="name">XXX商品001</a>
        <a slot="code">adcds</a>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "物品名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "物品编码",
    dataIndex: "code",
    scopedSlots: { customRender: "code" },
  },
  {
    title: "类型",
    dataIndex: "",
  },
  {
    title: "单据类型",
    dataIndex: "",
  },
  {
    title: "单据编号",
    dataIndex: "",
  },
  {
    title: "库存数量",
    dataIndex: "",
  },
  {
    title: "库存单位",
    dataIndex: "",
  },
  {
    title: "库存状态",
    dataIndex: "",
  },
  {
    title: "库存位置",
    dataIndex: "",
  },
];

const data = [
  {
    key: "1",
  },
];
export default {
  data() {
    return {
      data,
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
  methods: {
    // 跳转新增入库单
    goAdd() {
      this.$router.push({ path: `/Storage/AddInbound` });
    },
  },
};
</script>

<style lang="scss" scoped>
.inbound {
  padding: 30px;

  .info {
    background-color: #ffffff;
    padding: 30px;

    h1 {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
    }

    .filter-term {
      margin: 20px 0;
      .ant-row {
        margin-bottom: 15px;

        label {
          margin-right: 10px;
          width: 60px;
          display: inline-block;
          font-size: 14px;
        }

        .ant-input {
          width: 50%;
        }

        .ant-select {
          width: 50%;
        }
      }
    }
    .cutover {
      text-align: right;
      margin: 15px 0 30px 0;
    }
  }
}
</style>
